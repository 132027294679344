import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../../Layout"
import Seo from "../../../../components/Seo/Seo"
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb'
import TitleSection from '../../../../Molecules/TitleSection/TitleSection'
import MediaPageHeaderDesign from '../../../../assets/images/mediapage-design-header.inline.svg'
import './styles.scss'
var classNames = require('classnames');

const CommiteMemberPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "single-article-visu-desktop.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const list_member_direction = [
    {
      visuel: data.file.childImageSharp.fluid,
      fullname: 'Nom Prénom',
      post: 'Post occupé',
      link: '#'
    }
  ]

  return (
    <Layout>
      <Seo
        title="Colas - Réunion des analystes"
        description="Description de la page Réunion des analystes"
      />
      <div className={classNames('page_template', 'comite_member_page')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              grandParentPage: { title: "Acceuil", url: "/" },
              parentPage: { title: "Finance", url: "/" },
              currentPage: { title: "Réunion des analystes", url: "/finance/analyst-meeting" }
            }}
          />
        </div>
        <section className="section_content section_member_direction">
          <div className="wrapper_page">
            <TitleSection
              type="line"
              title="Frédéric Gardès"
              description="Président-Directeur Général"
              smallText="Né le 25 avril 1971"
            />
            <div className="section_main_content cross_wrapper_extreme_left">
              <div className="col_2">
                <div className="visuel">
                  <StaticImage
                    src='../../../../assets/images/varlorcol_desktop.png'
                    alt="A dinosaur"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </div>
                <div className="text">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate enim quia magnam dolorum perspiciatis harum qui aspernatur, delectus sed magni impedit et, reiciendis, asperiores voluptas corrupti debitis recusandae architecto labore!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  )

}

export default CommiteMemberPage
